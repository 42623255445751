/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

import { ColorConfig } from 'src/styles/themes/config/default-theme.config';
import { IpAddressService } from './application/services/ipAddress/ip-address.service';
import { ButtonTypeEnum } from './shared/enums/btn.enum';
import { ActionButton } from './shared/models/action-button.model';
import { TabsService } from './shared/services/browser-tabs/tabs.service';
import { ConfirmationModalService } from './shared/services/confirmation-modal/confirmation-modal.service';
import { InactivityTrackerService } from './shared/services/inactivity-tracker/inactivity-tracker.service';

import { LanguageSupportService } from './shared/services/language-support/language-support.service';
import { LoaderService } from './shared/services/loader-service/loader.service';
import { StorageService } from './shared/services/storage/storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    showHeader = false;
    showSidebar = false;
    showFooter = false;
    openSideMenu = false;
    title = 'IPPD-WEB-BASE';
    publicIP!:any

    constructor(
        private languageSupportService: LanguageSupportService,
        private storageService: StorageService,
        private inactivityTracker: InactivityTrackerService,
        private authservice: MsalService,
        private confirmationModalService: ConfirmationModalService,
        private loaderService: LoaderService,
        private tabService: TabsService,
        private router: Router,
        private ipAddressService: IpAddressService
    ) {
        this.initializeAppLanguage()
    }

    ngOnInit(): void {
        this.setGlobalColors();
        this.initiateInactivityTracker();
        // this.fetchIPAddress();
    }

    initiateInactivityTracker() {
        let userType = localStorage.getItem("UserType");
        if (userType) {
            if (userType == 'false') {

                this.inactivityTracker.startInactivityTracker(120).subscribe((isUserActive: boolean) => {
                    if (!isUserActive) {
                        const okBtn = new ActionButton({ btnId: 'yes', btnText: 'portal.genericText.ok', btnType: ButtonTypeEnum.PRIMARY_PURPLE })
                        const confirmationMsg = 'portal.genericMessages.sessionTimeout';
                        this.confirmationModalService.openConfirmationDialog(confirmationMsg, '', [okBtn]).then(response => {
                            if (okBtn.btnId === response) {
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('storeID');
                                localStorage.removeItem('storeName');
                                localStorage.removeItem('onCancel');
                                this.authservice.logout();
                                this.router.navigate(['login']);
                                // const win: Window = window;
                                // win.location = environment.simExternalLogoutUrl;
                                window.location.assign(environment.simExternalLogoutUrl)
                                this.loaderService.toggleLoader(true);
                                for (var i = 0; i < this.tabService.tabs?.length; i++) {
                                    this.tabService.tabs[i].close();
                                }
                            }
                        })
                    }
                });
            }
            else {
                this.inactivityTracker.startInactivityTracker(900).subscribe((isUserActive: boolean) => {
                    if (!isUserActive) {
                        const okBtn = new ActionButton({ btnId: 'yes', btnText: 'portal.genericText.ok', btnType: ButtonTypeEnum.PRIMARY_PURPLE })
                        const confirmationMsg = 'portal.genericMessages.sessionTimeout';
                        this.confirmationModalService.openConfirmationDialog(confirmationMsg, '', [okBtn]).then(response => {
                            if (okBtn.btnId === response) {
                                localStorage.removeItem('UserType');
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('storeID');
                                localStorage.removeItem('storeName');
                                localStorage.removeItem('onCancel');
                                this.authservice.logout();
                                this.loaderService.toggleLoader(true);
                            }
                        })
                    }
                });
            }
        }
    }


    private setGlobalColors() {
        document.documentElement.style.setProperty('--primary-color-light', ColorConfig.primary_light);
        document.documentElement.style.setProperty('--primary-color-medium', ColorConfig.primary_medium);
        document.documentElement.style.setProperty('--primary-color-dark', ColorConfig.primary_dark);

        document.documentElement.style.setProperty('--secondary-color-light', ColorConfig.secondary_light);
        document.documentElement.style.setProperty('--secondary-color-medium', ColorConfig.secondary_medium);
        document.documentElement.style.setProperty('--secondary-color-dark', ColorConfig.secondary_dark);

        document.documentElement.style.setProperty('--grey-color-light', ColorConfig.grey_light);
        document.documentElement.style.setProperty('--grey-color-medium', ColorConfig.grey_medium);
        document.documentElement.style.setProperty('--grey-color-dark', ColorConfig.grey_dark);
        document.documentElement.style.setProperty('--grey-color-border', ColorConfig.grey_border);
        document.documentElement.style.setProperty('--grey-color-shadow', ColorConfig.grey_shadow);


        document.documentElement.style.setProperty('--light-grey-color', ColorConfig.light_grey);
        document.documentElement.style.setProperty('--medium-grey-color', ColorConfig.medium_grey);
        document.documentElement.style.setProperty('--dark-grey-color', ColorConfig.dark_grey);

        document.documentElement.style.setProperty('--error-color', ColorConfig.error);
        document.documentElement.style.setProperty('--warning-color', ColorConfig.warning);

        document.documentElement.style.setProperty('--white-color', ColorConfig.white);
        document.documentElement.style.setProperty('--dark-color', ColorConfig.dark);
    }

    private initializeAppLanguage() {
        const lang = this.storageService.loadFromLocal('applicationLanguage');
        const langStr = lang ? JSON.parse(lang) : 'en';
        this.languageSupportService.initLanguageService(langStr);
    }

    toggleSideMenu() {
        this.openSideMenu = !this.openSideMenu;
    }

}
