/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component';
import { DialogData } from '../../interfaces/dialog-data.interface';
import { ActionButton } from '../../models/action-button.model';
import { DialogConfig } from '../../models/dialog-config.model';

/**
 * Confirmation Dialog Service
 *
 * ### Sample usage
 * Inject the service in the constructor of the component (say as confirmationModalService)
 * Call the openConfirmationDialog method
 *
 * openConfirmationDialog(title: string, subTitle?: string, actionButtons?: ActionButton[])
 * title: string -> Title of the modal. (Mandatory field)
 * subTitle: string -> Subtitle text (Default text is "empty string")
 * actionButtons: ActionButton[] -> Buttons to be shown in the modal (Default is OK button)
 *
 * openConfirmationDialog returns a Promise of type "string", i.e. btnId of the clicked button.
 *
 */
@Injectable({
    providedIn: 'root'
})
export class ConfirmationModalService {

    constructor(private dialog: MatDialog) { }

    openConfirmationDialog(title: string, subTitle?: string, actionButtons?: ActionButton[]): Promise<string> {
        const dialogConfig = new DialogConfig(this.constructDialogData(title, subTitle, actionButtons));
        const defaultConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            maxHeight: '90vh',
            minWidth: '40vw',
            panelClass: 'background-white'
        }
        return new Promise<string>((resolve) => {
            if(this.dialog.openDialogs.length == 0) {
                const dialog = this.dialog.open(ConfirmationModalComponent, {...dialogConfig, ...defaultConfig});

                dialog.afterClosed().subscribe((result: ActionButton) => {
                    resolve(result.btnId);
                });
            }
        });
    }

    private constructDialogData(title: string, subTitle?: string, actionButtons?: ActionButton[]): DialogData {
        const button = new ActionButton();
        return {
            title,
            subTitle: subTitle || '',
            actionButtons: actionButtons || [button]
        };
    }
}
