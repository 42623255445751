<div class="application-layout-wrapper height-100-percent align-center justify-center">
    <div class="application-layout pos-rel background-white">
        <div class="five-below-logo pt-l pb-l justify-center">
            <img class="five-below-logo-img" src="./assets/images/five_below.png" alt="five-below-logo" />
        </div>

        <div class="title align-center justify-center">
            {{ "portal.searchStore.headerTitle" | translate }}
        </div>


        <div class="align-center justify-center mt-3">
        <input type="text" [(ngModel)]="searchText" (input)="fetchAllStores()" class="bordered-input" [placeholder]="'portal.searchStore.searchPlaceHolder' | translate">
        <span suffix class="material-icons pos-rel top-15px searchIcon">search</span>
        </div>
          
        <div class="mt-3 scrollbar" id="custom-scroll" *ngIf="!noStores">
            <div *ngFor="let store of allStores">
                <div class="row mt-1" (click)="onStoreClick(store)" >
                    <div class="col-sm-12" style="margin-left: 10px; cursor: pointer;">
                        <h5>{{store}}</h5>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div *ngIf="noStores">
            <p class="text-center"> No Stores Found</p>
        </div>

        <div class="align-center justify-center mt-2">
            <app-btn class="continue" [btnType]="buttonType.SECONDARY_BTN" [content]='"portal.genericText.cancel"' (clicked)="onCancel()">
            </app-btn>
        </div>

        <div class="footer pos-abs bottom-0px justify-center width-100-percent">
            <div class="footer-content width-70-percent">
                <hr class="hr_line" />
                <div class="privacy-tnc pa-m">
                    <div class="justify-center footer-message">
                        By signing in you agree to Five Below's
                    </div>
                    <div class="justify-center">
                        <a class="highlighted-txt" href="https://www.fivebelow.com/terms" target="blank">{{
                            "Terms of
                            Use"
                            }}</a>
                        <span class="ml-s mr-s">and</span>
                        <a class="highlighted-txt" href="https://www.fivebelow.com/privacy" target="blank">
                            {{
                            "Privacy
                            Policies"
                            }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>