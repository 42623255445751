/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
    LogLevel,
    IPublicClientApplication,
    PublicClientApplication,
    BrowserCacheLocation,
    InteractionType,
} from '@azure/msal-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { from, Observable } from 'rxjs';


import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AppPreloadingStrategy } from './shared/utililities/app-preloading-strategy';
import { GlobalErrorHandler } from './shared/modules/app-logger/services/error-handler/global-error-handler.service';
import { environment } from 'src/environments/environment';
import { LoginModule } from './login/login.module';
import { OnLoanComponent } from './on-loan/on-loan.component';
import { SearchStoreComponent } from './search-store/search-store.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export class TranslationLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return from(import(`src/assets/i18n/${lang}.json`));
    }
}

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
    let text=message;
    if(text.match(/handleRedirectPromise did not detect a response hash as a result of a redirect. Cleaning temporary cache/g)){
      window.location.reload()
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {

            clientId: environment.b2cPolicies.clientId, // add the clientId here
            authority: environment.b2cPolicies.authorities.signUpSignIn, // add the authority url here
            redirectUri: environment.msalRedirectUri,
            postLogoutRedirectUri: environment.msalRedirectUri,
            knownAuthorities: [environment.b2cPolicies.authorityDomain]


        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE
        },
        system: {
            iframeHashTimeout: 10000000,
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.b2cPolicies.uri, environment.b2cPolicies.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.b2cPolicies.scopes]
        }
    };
}

export function appInsightsFactory(): ApplicationInsights {
    const instrumentationKey = environment.instrumentationKey || '';
    return new ApplicationInsights({
        config: {
            instrumentationKey
        }
    });
}

@NgModule({
    declarations: [
        AppComponent,
        OnLoanComponent,
        SearchStoreComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoader,
            }
        }),
        MsalModule,
        LoginModule
    ],
    providers: [
        AppPreloadingStrategy,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: ApplicationInsights,
            useFactory: appInsightsFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ]
})
export class AppModule {
}
