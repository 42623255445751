import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private browserTabs:any = [];
  urls:any = [];

  constructor() { }

  get tabs() {
    return this.browserTabs;
  }
  
  set tabs(tabs) {
    this.browserTabs.push(tabs);
  }

}
