/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
// Core Package Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// External Package Imports
import { TranslateModule } from '@ngx-translate/core';

// Module Imports
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { AppLoggerModule } from './modules/app-logger/app-logger.module';
import { InterceptorModule } from './modules/interceptors/interceptors.module';
import { HttpModule } from './modules/http-layer/http.module';

// Directive Imports
import { DebounceClickDirective } from './directives/debounce-click/debounce-click.directive';

// Pipe Imports
import { HtmlSanitizePipe } from './pipes/html-sanitize/html-sanitize.pipe';

// Component Imports
import { BtnComponent } from './components/btn/btn.component';
import { GenericInputComponent } from './components/generic-input/generic-input.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { StorageService } from './services/storage/storage.service';

import { RouterModule } from '@angular/router';
// import { SwiperModule } from "swiper/angular";

// import SwiperCore, { Navigation, Pagination } from "swiper/core";

// install Swiper components
// SwiperCore.use([
//     Navigation,
//     Pagination
// ]);

const directives = [
  DebounceClickDirective
] as const;

const components = [
  BtnComponent,
  GenericInputComponent,
  ConfirmationModalComponent,
] as const;

const pipes = [
  HtmlSanitizePipe
] as const;

@NgModule({
  declarations: [
    ...directives,
    ...components,
    ...pipes,
    
  ],
  imports: [
      RouterModule,
      CommonModule,
      AngularMaterialModule,
      InterceptorModule.forRoot(),
      TranslateModule,
      HttpModule,
      ReactiveFormsModule,
      FormsModule,
      AppLoggerModule,
      // SwiperModule,
  ],
  exports: [
    ...directives,
    ...components,
    ...pipes,
    TranslateModule,
    AngularMaterialModule,
    AppLoggerModule,
    InterceptorModule,
    HttpModule,
    ReactiveFormsModule,
    FormsModule,
    // SwiperModule
  ],
  providers: [
    SnackBarService,
    StorageService
  ]
})
export class SharedModule { }
