<div [ngClass]="{'mb-l': data.subTitle | translate}">
    <div mat-dialog-title class="text-center" style="font-size: 14px;">{{data.title | translate}}</div>
    <div *ngIf="data.subTitle" mat-dialog-content>{{data.subTitle | translate}}</div>
</div>
<div class="justify-center m-2">
    <ng-container *ngFor="let button of data.actionButtons">
        <span class="pr-s pl-s" style="padding-bottom: 20px">
            <app-btn
                [btnType]="button.btnType"
                [content]="button.btnText | translate"
                (clicked)="closeDialog(button)">
            </app-btn>
        </span>
    </ng-container>
</div>