/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageSupportService } from '../language-support/language-support.service';

@Injectable()
export class SnackBarService {

    constructor(
        private _snackBar: MatSnackBar,
        private languageSupportService: LanguageSupportService) { }

    triggerSnackBar(message: string, action = 'portal.genericText.close') {
        const snackBarMsg = this.languageSupportService.translate.instant(message);
        const actionText = this.languageSupportService.translate.instant(action);
        this._snackBar.open(snackBarMsg, actionText, { duration: 5000 });
    }
}
