import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, Observer, fromEvent, merge } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InactivityTrackerService {

    isUserActive: boolean = true;
    trackerStartTime: any;
    startInactivityTracker(inactiveTimeThreshold: number): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            this.isUserActive = false;
            const events = ['mousemove', 'keypress', 'click'];
            const eventStreams = events.map((ev) => fromEvent(document, ev));
            const allEvents$ = merge(...eventStreams);
            allEvents$.subscribe(() => {
                this.trackerStartTime = moment();
                if (!this.isUserActive) {
                    this.isUserActive = true;
                    this.startInactivityMonitor(observer, inactiveTimeThreshold);
                    observer.next(this.isUserActive);

                }
            });
        });
    }
    startInactivityMonitor(observer: Observer<boolean>, inactiveTimeThreshold: number) {
        setTimeout(() => {
            if (moment().diff(this.trackerStartTime, 'seconds') >= inactiveTimeThreshold) {
                this.isUserActive = false;
                observer.next(this.isUserActive);
            } else {

                this.startInactivityMonitor(observer, inactiveTimeThreshold);
            }
        }, (inactiveTimeThreshold) * 1000);
    }
}
