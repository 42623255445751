import { ErrorHandler,Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {

    constructor(private appInsights: ApplicationInsights) {
        super()
    }

    handleError(error: any) {
        this.appInsights.trackException({ exception: error })
    }
}
