<button matRipple [disabled]="disabled"
    class="cust-btn display-flex-inline align-center justify-center"
    [ngClass]="{
        'cust-primary-btn-purple': btnType === ButtonTypeEnum.PRIMARY_PURPLE,
        'cust-primary-btn-yellow': btnType === ButtonTypeEnum.PRIMARY_YELLOW,
        'cust-secondary-btn': btnType === ButtonTypeEnum.SECONDARY_BTN,
        'cust-link-btn': btnType === ButtonTypeEnum.LINK_BTN,
        'btn-sm': btnSize === ButtonSizeEnum.SMALL,
        'disable': disabled,
        'height-lg': contentSize === ButtonContentSizeEnum.LARGE
    }"
    appDebounceClick (debounceClick)="this.enableDebounce && triggerClick()"
    (click)="!this.enableDebounce && triggerClick()">
    <span *ngIf="iconPos === ButtonIconPosEnum.RIGHT"
        [ngClass]="{'flex-text-ellipsis': enableEllipsis}" >
        {{content | translate}}
    </span>
    <ng-content></ng-content>
    <span *ngIf="iconPos === ButtonIconPosEnum.LEFT"
        [ngClass]="{'flex-text-ellipsis': enableEllipsis}">
        {{content | translate}}
    </span>
</button>