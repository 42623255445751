/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/

import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    private genericNameSpace = 'com.app.liveenterprise';

    /**
     * A method to generate storage key 
     * @param key  mandatory storage key path
     * @param additionalKeys  additional optional storage key paths
     * @returns The storage key value
     */
    private generateStorageKey(key: string, ...additionalKeys: string[]) {
        let storageKey = `${this.genericNameSpace}.${key}`;
        for (const addKey in additionalKeys) {
            storageKey = `${storageKey}.${addKey}`;
        }
        return storageKey
    }

    /**
     * A method to save data to the local storage
     * @param value  The data to be stored in local
     * @param key  mandatory storage key path
     * @param additionalKeys  additional optional storage key paths
     */
    storeInLocal(value: any, key: string, ...additionalKeys: string[]) {
        const storageKey = this.generateStorageKey(key, ...additionalKeys);
        const storageValue = JSON.stringify(value);
        localStorage.setItem(storageKey, storageValue);
    }

    /**
     * A method to remove data from the local storage
     * @param key  mandatory storage key path
     * @param additionalKeys  additional optional storage key paths
     */
    removeFromLocal(key: string, ...additionalKeys: string[]) {
        localStorage.removeItem(this.generateStorageKey(key, ...additionalKeys));
    }

    /**
     * A method to get the data from the local storage
     * @param key  mandatory storage key path
     * @param additionalKeys  additional optional storage key paths
     * @returns The data stored in the local storage of the given key
     */
    loadFromLocal(key: string, ...additionalKeys: string[]) {
        return localStorage.getItem(this.generateStorageKey(key, ...additionalKeys));
    }

    /**
     * A method to clear the all data from the local storage
     */
    clearLocalStorage() {
        for (const key in localStorage) {
            if ((Object.prototype.hasOwnProperty.call(localStorage, key))
                && (key.search(this.genericNameSpace) > -1)) {
                localStorage.removeItem(key);
            }
        }
    }
}
