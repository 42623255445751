/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";

/**
 * Service to avoid memory leaks by auto-closing subscription streams
 *
 * Add this service in components providers list
 *
 * For each subscription pipe it through takeUntil()
 * Pass the service instance in takeUntil(which is a subscription)
 *
 * ## EXAMPLE
 *
 * ```ts
 * @Component({
 *              selector: 'app-sample',
 *              providers: [SubscribeDestroyService]
 * })
 * export class SampleComponent implements OnInit{
 *              constructor(private destroy$: SubscribeDestroyService, private someService: SomeService) { }
 *
 *              ngOnInit() {
 *                  this.someService.someMethod
 *                  .pipe(takeUntil(this.destroy$))
 *                  .subscribe(doSomething())
 *              }
 * }
 * ```
 */
@Injectable()
export class SubscribeDestroyService extends Observable<void> implements OnDestroy {

    private readonly subscriberLife$ = new Subject<void>();

    constructor() {
        super(subscribe => this.subscriberLife$.subscribe(subscribe))
    }

    ngOnDestroy() {
        this.subscriberLife$.next();
        this.subscriberLife$.unsubscribe();
    }
}
