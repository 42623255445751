/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
export enum ButtonSizeEnum {
    NORMAL = 'NORMAL',
    SMALL = 'SMALL'
}
export enum ButtonIconPosEnum {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}
export enum ButtonContentSizeEnum {
    NORMAL = 'NORMAL',
    LARGE = 'LARGE'
}

export enum ButtonTypeEnum {
    PRIMARY_PURPLE = 'PRIMARY_PURPLE',
    PRIMARY_YELLOW = 'PRIMARY_YELLOW',
    SECONDARY_BTN = 'SECONDARY_BTN',
    LINK_BTN = 'LINK_BTN'
}
