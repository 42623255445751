/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/

import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import DirectiveConfig from '../../config/directive-config';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {

  @Input() debounceTime = DirectiveConfig.DEBOUNCE_CLICK_TIME; // unit ms

  @Output() debounceClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription | undefined;


  ngOnInit(): void {
    this.subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceClick.emit(e));
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
