import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { IpAddressService } from '../application/services/ipAddress/ip-address.service';
import { ButtonTypeEnum } from '../shared/enums/btn.enum';
import { ActionButton } from '../shared/models/action-button.model';
import { ConfirmationModalService } from '../shared/services/confirmation-modal/confirmation-modal.service';
import { LoaderService } from '../shared/services/loader-service/loader.service';
import { SnackBarService } from '../shared/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-on-loan',
  templateUrl: './on-loan.component.html',
  styleUrls: ['./on-loan.component.scss']
})
export class OnLoanComponent implements OnInit {
  buttonType = ButtonTypeEnum;
  store:any;

  constructor(private router: Router,
              private ipAddressServ: IpAddressService,
              private loaderService: LoaderService,
              private confirmationModalService: ConfirmationModalService,
              private authservice: MsalService,
              private snackBar: SnackBarService) { }

  ngOnInit(): void {
    localStorage.getItem('storeName') ? this.fetchStoreAddressByName() : this.fetchIPandStoreAddress();
  }

  onContinue() {
    this.router.navigate(['application'])
  }

  fetchIPandStoreAddress() {
    this.ipAddressServ.getPublicAddressFromIpify().subscribe(
      (res:any) => {
        this.fetchStoreAddress(res.ip);
      },
      (error:any) => {
          const okBtn = new ActionButton({ btnId: 'yes', btnText: 'portal.loginForm.logout', btnType: ButtonTypeEnum.PRIMARY_PURPLE })
          const confirmationMsg = 'portal.genericMessages.ipAddressError';
          this.confirmationModalService.openConfirmationDialog(confirmationMsg, '', [okBtn]).then(response => {
              if (okBtn.btnId === response) {
                localStorage.removeItem('UserType');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('storeID');
                this.authservice.logout();
                this.router.navigate(['login']);          
              }
          })
      }
    )
  }

  fetchStoreAddress(ip:any) {
        let obj = {
            "ipAddress": ip,
            "isPublicIp" : true
        }
        this.ipAddressServ.getStoreAddress(obj).subscribe(
          (res:any)=>
            {
              this.store = res?.response[0];
              localStorage.setItem('storeID', this.store.storeId)
              this.loaderService.clearLoader();
            },
          (error:any) => 
            {
              this.handleOnCancel();
              this.loaderService.clearLoader();
            }
        )
  }

  fetchStoreAddressByName() {
    const nameObj = {
      "name": localStorage.getItem('storeName')
    }

    this.ipAddressServ.getStoreDetailsById(nameObj).subscribe(
      (res:any) => {
        this.store = res?.response[0];
        localStorage.setItem('storeID', this.store.storeId);
        localStorage.removeItem('storeName');
        this.loaderService.clearLoader();
      },
      (error:any) => {
        
      },
    )
  }

  handleOnCancel() {
    localStorage.getItem('onCancel') == 'true' ?  this.snackBar.triggerSnackBar('portal.genericMessages.noStoreDetails') : '';
    localStorage.removeItem('onCancel');
  }
}
