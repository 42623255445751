import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {
  ipifyUrl = environment.ipifyURL;
  storeLocationUrl = environment.storeLocationUrl;

  constructor(private http: HttpClient) { }

  getPublicAddressFromIpify() {
    return this.http.get(this.ipifyUrl);
  }

  getStoreAddress(obj:any) {
    const token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    return this.http.post(this.storeLocationUrl + '/address', obj, { headers });
  }

  getAllStores(obj:any) {
    return this.http.post(this.storeLocationUrl + '/searchStores', obj);
  }

  getStoreDetailsById(nameObj:any) {
    return this.http.post(this.storeLocationUrl + '/getStoreDetailsById', nameObj);
  }
}
