<main class="main_content_container pos-abs width-100-percent display-flex"
    [ngClass]="{'header_active': showHeader, 'footer_active': showFooter}">
    <div class="main_content pos-rel justify-between width-100-percent height-100-percent"
        [ngClass]="{'display-flex-column': showFooter, 'display-flex': !showFooter}">
        <div class="width-100-percent">
            <router-outlet style="display: none;"
                (activate)="initiateInactivityTracker()">
            </router-outlet>
        </div>

    </div>
</main>
<app-loader></app-loader>