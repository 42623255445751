/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';

/**
 * Interceptor adds 'X-Requested-With' into outgoing http request
 */
@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

  accessToken:string = '';

  constructor(private authService: MsalService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.getRefreshToken();

    if (this.accessToken && this.shouldAddAuthorizationHeader(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-API-KEY': environment.xApiKeyValue,
        }
      });
    }

    return next.handle(request);
  }

  private shouldAddAuthorizationHeader(url: string): boolean {
    const urlsToSkip = [environment.apiEndPoints.base, environment.storeLocationUrl];
    return urlsToSkip.some((u) => url.startsWith(u));
  }

  getRefreshToken() {
    const obj = {
      authority: environment.b2cPolicies.authorities.signUpSignIn,
      scopes: [...environment.b2cPolicies.scopes]
    }

    this.authService.acquireTokenSilent(obj).subscribe(
      res => {
        this.accessToken = res.accessToken;
        localStorage.setItem('accessToken', this.accessToken);
      },
      error => {
        error.errorCode == 401 ? this.signOut() : ''
      }
    );
  }

  signOut() {
    this.authService.logout();
  }
}
