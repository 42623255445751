import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonContentSizeEnum, ButtonIconPosEnum, ButtonSizeEnum, ButtonTypeEnum } from '../../enums/btn.enum';

import { ButtonContentSize, ButtonIconPos, ButtonSize, ButtonType } from '../../interfaces/btn.interface';

/**
 * Custom button component
 * ### Sample usage
 * ```
 * <app-btn [btnSize]="ButtonSizeEnum.SMALL"
 * [btnType]="ButtonTypeEnum.SECONDARY_BTN" [content]="'itAdminPortal.secondaryButtons.previousBtn'"
 * (clicked)="triggerAction(VerifypopupAction.PREV_CAND)">
 * </app-btn>
 * ```
 * ```
 * btnType:  'PRIMARY_PURPLE' (default) | 'PRIMARY_YELLOW' | 'SECONDARY_BTN' | 'LINK_BTN' (ButtonTypeEnum)
 * btnSize: 'NORMAL' (default) | 'SMALL' (ButtonSizeEnum)
 * disabled: boolean
 * content: string
 * enableEllipsis: boolean // adds ellispsis to the button content. Add a width to parent selector when needed
 * enableDebounce: boolean // Click event will be emitted after user stops clicking
 * iconPos: 'LEFT' | 'RIGHT'(default) (ButtonIconPosEnum) // Icon can be supplied as ng-content.
 * contentSize: 'NORMAL' (default) | 'LARGE' (ButtonContentSizeEnum) // Button height will be adjusted if content exceeds two lines.
 *
 * clicked: Triggers event when button is triggered
 * ```
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent  {

  @Input() btnType: ButtonType = ButtonTypeEnum.PRIMARY_PURPLE;

  @Input() btnSize: ButtonSize = ButtonSizeEnum.NORMAL;

  @Input() disabled = false;

  @Input() content = '';

  @Input() enableDebounce = false;

  @Input() iconPos: ButtonIconPos = ButtonIconPosEnum.RIGHT;

  @Input() enableEllipsis = false;

  @Input() contentSize: ButtonContentSize = ButtonContentSizeEnum.NORMAL;

  @Output() clicked = new EventEmitter();

  ButtonTypeEnum = ButtonTypeEnum;

  ButtonSizeEnum = ButtonSizeEnum;

  ButtonIconPosEnum = ButtonIconPosEnum;

  ButtonContentSizeEnum = ButtonContentSizeEnum;


  triggerClick(): void {
    this.clicked.emit();
  }

}
