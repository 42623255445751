/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpHeaderInterceptor } from './http-header.interceptor';
import { HttpErrorInterceptor } from './http-error.interceptor';


/**
 * Module for All Interceptors
 */
@NgModule({
  imports: [
    CommonModule
  ]
})
export class InterceptorModule {
  static forRoot(): ModuleWithProviders<InterceptorModule> {
    return {
      ngModule: InterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpHeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true
        }
      ]
    };
  }
}
