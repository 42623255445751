/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/

/**
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 */

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */

 export const environment = {
    production: false,
     msalRedirectUri: 'https://wowcrewdev.svc.fivebelow.com/',
    //  msalRedirectUri: 'http://localhost:4200/',
    adLogout: 'https://login.microsoftonline.com/7b5043c5-ff30-434b-b66e-f8cf457db68a/oauth2/logout?client_id=15b62ddc-31e2-4586-bcd3-e20361881af8',
    apiEndPoints: {
        // base: 'http://localhost:8081/api/v1/onboarding', // add the baseUrl her ex: https://reqres.in
        // base: 'http://localhost:9008/api/v1/onboarding', // add the baseUrl her ex: https://reqres.in
        base: 'https://wowcrewdev.svc.fivebelow.com/onboarding/api/v1/onboarding', // add the baseUrl her ex: https://reqres.in
    },
    instrumentationKey: '',
    xApiKeyValue: '',
    apiEndPointServerVersion: 1,
    storeReportBaseUrl:'https://storereporting-dev.svc.fivebelow.com/?source=wow-crew',
    SIMUrl:'https://fived.cga.omnib.manh.com',
    b2cPolicies : {
        clientId: '819c20ca-e8a9-417a-911c-2395b2cdff53',
        names: {
            signUpSignIn: 'B2C_1A_5BSIGNUP_SIGNIN',
            editProfile: 'B2C_1A_5BPROFILEEDIT',
            passwordReset: 'B2C_1A_5BPWDRESET',
            signUpSignInSAML: 'B2C_1A_5BSIGNUP_SIGNIN_SAML',
        },
        authorities: {
            signUpSignIn: 'https://fivebelowb2cdev.b2clogin.com/tfp/fivebelowb2cdev.onmicrosoft.com/B2C_1A_5BSIGNUP_SIGNIN',
            editProfile: 'https://fivebelowb2cdev.b2clogin.com/tfp/fivebelowb2cdev.onmicrosoft.com/B2C_1A_5BPROFILEEDIT',
            passwordReset: 'https://fivebelowb2cdev.b2clogin.com/tfp/fivebelowb2cdev.onmicrosoft.com/B2C_1A_5BPWDRESET',
            signUpSignInSAML: 'https://fivebelowb2cdev.b2clogin.com/tfp/fivebelowb2cdev.onmicrosoft.com/signUpSignInSAML'
        },
        authorityDomain: 'fivebelowb2cdev.b2clogin.com',
        scopes: [
            'https://fivebelowb2cdev.onmicrosoft.com/f1c0bdba-4549-410b-acf3-b3426b590264/user_impersonation'
        ],
        uri: 'https://fivebelowb2cdev.onmicrosoft.com/f1c0bdba-4549-410b-acf3-b3426b590264',
        prompt:'login'
    },
    simExternalLogoutUrl: 'https://fived.cga.omnib.manh.com/logout',
    ipifyURL: 'https://api.ipify.org?format=json',
    storeLocationUrl: 'https://wowcrewdev.svc.fivebelow.com/location/api/v1/store'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
 */

