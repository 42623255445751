<div class="login-container height-100-percent width-100-percent align-center justify-center">
    <div class="login-content background-white rounded-corner">
        <div class="pos-rel height-100-percent">
            <div class="logo display-flex-column align-center pt-l">
                <img src="assets/images/five_below.png"
                    class="fivebelow-logo mt-l mb-l"
                    alt="FiveBelow">
                <div class="welcome-text ">{{'portal.loginForm.fiveBelowWelcome'|translate}}</div>
            </div>
            <div class="display-flex-column align-center mt-l">
                <img class="image"
                    src="assets/images/cool-stuff-logo.png"
                    alt="cool-stuff-logo">
                <div class="mt-m mb-m">
                    <app-btn class="continue"
                        [btnType]="buttonType.PRIMARY_PURPLE"
                        [content]='"portal.genericText.signIn"'
                        (clicked)="loginViaMsal()">
                    </app-btn>
                </div>
                <div class="forgot-password pointer"
                    (click)="triggerForgotPassword()">
                    {{'portal.loginForm.forgotPassword'|translate}}
                </div>
            </div>
            <div class="footer pos-abs bottom-0px justify-center width-100-percent">
                <div class="footer-content width-70-percent">
                    <hr class="hr_line">
                    <div class="privacy-tnc pa-m">
                        <div class="footer-policy-content justify-center">By signing in you agree to Five Below's</div>
                        <div class="justify-center">
                            <a class="highlighted-txt"
                                href="https://www.fivebelow.com/terms"
                                target="blank">{{'Terms of Use'}}</a>
                            <span class="ml-s mr-s">and</span>
                            <a class="highlighted-txt"
                                href="https://www.fivebelow.com/privacy"
                                target="blank"> {{'Privacy Policies'}}</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>