/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/components/login/login.component';
import { OnLoanComponent } from './on-loan/on-loan.component';
import { AppPreloadingStrategy } from './shared/utililities/app-preloading-strategy';
import { SearchStoreComponent } from './search-store/search-store.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'store',
        component: OnLoanComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'searchStoreLocation',
        component: SearchStoreComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'application',
        loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule),
        data: { showHeader: true, showSidebar: true, showFooter: true, preload: false },
        canActivate: [MsalGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        data: { showHeader: true, showSidebar: true, showFooter: true, preload: false },
        canActivate: [MsalGuard]
    },
    { 
        path: 'access_token',
        redirectTo: 'login'
    },
    { 
        path: 'id_token',
        redirectTo: 'login'
    },
    { 
        path: 'error',
        redirectTo: 'login'
    },
    {
        path: 'code',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'state',
        component: LoginComponent,
        pathMatch: 'full'
    },

    {
        path: "**",
        component: LoginComponent
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        preloadingStrategy: AppPreloadingStrategy
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
