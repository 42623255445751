/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Component } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

import { SubscribeDestroyService } from 'src/app/shared/services/subscribe-destroy.service';
import { ButtonTypeEnum } from 'src/app/shared/enums/btn.enum';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/shared/services/snack-bar/snack-bar.service';
import { LoaderService } from 'src/app/shared/services/loader-service/loader.service';
import { takeUntil } from 'rxjs/operators';
import { InactivityTrackerService } from 'src/app/shared/services/inactivity-tracker/inactivity-tracker.service';
import { ActionButton } from 'src/app/shared/models/action-button.model';
import { ConfirmationModalService } from 'src/app/shared/services/confirmation-modal/confirmation-modal.service';
import { IpAddressService } from 'src/app/application/services/ipAddress/ip-address.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [SubscribeDestroyService]
})
export class LoginComponent {

    buttonType = ButtonTypeEnum;
    publicIP!:any
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private destroy$: SubscribeDestroyService,
        private loaderService: LoaderService,
        private snackBar: SnackBarService,
        private inactivityTracker: InactivityTrackerService,
        private confirmationModalService: ConfirmationModalService,
        private ipAddressService: IpAddressService

    ) { }

    ngOnInit(): void {
        this.loaderService.toggleLoader(true);
        this.route.url.subscribe((urlList: UrlSegment[]) => {
            if(urlList.find((url: UrlSegment) => url.path.includes('login'))) {
                setTimeout(() => {
                    this.loaderService.toggleLoader(false);
                }, 3000);
            } 
            if(urlList.find((url: UrlSegment) => url.path.includes('state') && url.path.includes('profile'))) {
                this.loaderService.toggleLoader(true);
            } 
        });
        this.initializeSubscribers();
        this.checkStorage();

        this.authService.handleRedirectObservable().subscribe({
            next: (result: any) => {
              if(result.account?.homeAccountId.toUpperCase().includes(environment.b2cPolicies.names.passwordReset)) {
                  localStorage.setItem('changePassword', 'true');
              }
        
              if (environment.b2cPolicies.authorities.editProfile.toLowerCase().includes(result.idTokenClaims['acr'])) {
                localStorage.setItem('changeSecurityQandA', 'true');
              }
            }
          });

    }


    checkStorage() {

        if (localStorage.getItem('fromProfile')) {
            this.loaderService.toggleLoader(true)
            localStorage.removeItem('fromProfile');
            this.router.navigate(['profile']);
        } else {

            if (sessionStorage.getItem('isCorpUser')) {

                sessionStorage.removeItem('isCorpUser');
                window.open(environment.adLogout, '_self');
            }

        }
    }

    initializeSubscribers() {

        this.msalBroadcastService.msalSubject$
            .pipe(takeUntil(this.destroy$))
            .subscribe((result: EventMessage) => { 
                if (result.eventType === EventType.LOGIN_SUCCESS) {
                    this.loaderService.toggleLoader(true);
                    const payload = result.payload as AuthenticationResult;
                    this.authService.instance.setActiveAccount(payload.account);
                    const skipCheck = payload.authority.toUpperCase().includes(environment.b2cPolicies.names.passwordReset);
                    let checkLoginStatus = localStorage.getItem('UserType');
                    if (checkLoginStatus) {
                        this.authService.instance.setActiveAccount(payload.account);
                    }
                    setTimeout(() => {
                        this.checkForLogin(skipCheck);
                    }, 500);
                }
                else if (result.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                    this.fetchTokenSilent();
                }
                else if (result.eventType === EventType.LOGIN_FAILURE) {
                    this.loaderService.toggleLoader(false);
                    if(result.error?.message.includes('The user has cancelled entering self-asserted information')){
                        return;
                    } else {
                        this.snackBar.triggerSnackBar('portal.genericMessages.loginFailed');
                    }
                    
                }
            }
        );
    }

    fetchTokenSilent() {
        const obj = {
            authority: environment.b2cPolicies.authorities.signUpSignIn,
            scopes: [...environment.b2cPolicies.scopes]
        }
        if (this.authService.acquireTokenSilent(obj).subscribe()) {
            if (this.checkForActiveAccount()) {
                localStorage.setItem('fromProfile', 'true');
                window.location.reload();
            }
        }
    }

    loginViaMsal() {
        if (this.checkForActiveAccount()) {
            this.loaderService.clearLoader();
            this.router.navigate(['application'])
            this.loaderService.toggleLoader(false);
        } else {
            this.authService.loginRedirect({
                authority: environment.b2cPolicies.authorities.signUpSignIn,
                scopes: environment.b2cPolicies.scopes,
                prompt: environment.b2cPolicies.prompt
            });
        }
    }

    triggerForgotPassword() {
        this.loaderService.toggleLoader(true);
        this.authService.loginRedirect({
            authority: environment.b2cPolicies.authorities.passwordReset,
            scopes: environment.b2cPolicies.scopes,
            prompt: environment.b2cPolicies.prompt
        });
        this.loaderService.toggleLoader(false);
        this.loaderService.clearLoader();
    }

    private checkForLogin(skipCheck: boolean) {
        if (!skipCheck && this.checkForActiveAccount()) {
            this.loaderService.toggleLoader(true);
            // this.loaderService.clearLoader();
            this.router.navigate(['store']);
        } else if (skipCheck && this.checkForActiveAccount()) {
            this.loaderService.toggleLoader(false);
            this.loaderService.clearLoader();
            this.router.navigate(['profile']);
        }
        else {
            this.loaderService.toggleLoader(false);
            this.loaderService.clearLoader();
            this.router.navigate(['login']);
        }
    }

    private checkForActiveAccount() {
        const userInfo = this.authService.instance.getAllAccounts().filter((a: AccountInfo) => {
            return a.homeAccountId.includes(environment.b2cPolicies.names.signUpSignIn.toLocaleLowerCase())
        }
        )[0];
        if (userInfo) {
            const idTokenClaims: any = userInfo.idTokenClaims;
            localStorage.setItem('UserType', idTokenClaims.isCorpUser);
        }
        return userInfo ? true : false;

    }
}
