/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
export const ColorConfig = {

    primary_light: '#b3d1ff',
    primary_medium: '#0066ff',
    primary_dark: '#0053ff',

    secondary_light: '#dbbeed',
    secondary_medium: '#8626c3',
    secondary_dark: '#731cb5',
    
    grey_light: '#f3f3f3',
    grey_medium: '#eeeeee',
    grey_dark: '#777777',
    grey_border:'#b4b4b4',
    grey_shadow:'#6a6a6a',


    light_grey:'#f2f2f2',
    dark_grey:'#353535',
    medium_grey:'#484848',
    
    error: '#f00',
    warning: '#ffff00',

    white: '#fff',
    dark: '#3f3f3f'
}