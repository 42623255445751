<div class="onloan-container height-100-percent width-100-percent align-center justify-center">
    <div class="onloan-content background-white rounded-corner">
        <div class="pos-rel height-100-percent">
            <div class="logo display-flex-column align-center">
                <img src="assets/images/five_below.png"
                    class="fivebelow-logo mt-l mb-l"
                    alt="FiveBelow">
            </div>
            <div class="display-flex-column align-center mt-l">
                <img class="image" src="assets/images/cool-stuff-logo.png" alt="cool-stuff-logo">

                <div class="mt-4 text-center" style="font-family: sans-serif;" *ngIf="store">
                    <h3><strong>#{{store.storeId}} {{store.city}}</strong></h3>
                    <h3>{{store.street}}</h3>
                    <h3>{{store.city}}, {{store.region}} {{store.postalCode}}</h3>
                </div>
                <div class="mt-m mb-m" *ngIf="!store">
                    <div class="forgot-password pointer" [routerLink]="['/searchStoreLocation']">
                        <h2><b>{{'portal.genericText.setLocation'|translate}}</b></h2>
                    </div>
                </div>
                
                <app-btn class="continue" [disabled]="store ? false : true"
                        [btnType]="buttonType.PRIMARY_PURPLE"
                        [content]='"portal.genericText.continue"'
                        (clicked)="onContinue()">
                </app-btn>
            </div>
            <div class="footer pos-abs bottom-0px justify-center width-100-percent">
                <div class="footer-content width-70-percent">
                    <hr class="hr_line">
                    <div class="privacy-tnc pa-m">
                        <div class="footer-policy-content justify-center">By signing in you agree to Five Below's</div>
                        <div class="justify-center">
                            <a class="highlighted-txt"
                                href="https://www.fivebelow.com/terms"
                                target="blank">{{'Terms of Use'}}</a>
                            <span class="ml-s mr-s">and</span>
                            <a class="highlighted-txt"
                                href="https://www.fivebelow.com/privacy"
                                target="blank"> {{'Privacy Policies'}}</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>