/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageSupportService {

    constructor(public translate: TranslateService) { }

    initLanguageService(language = 'en'): void {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang(language);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use(language);
    }

    useSpecificLang(lang: string): void {
        this.translate.use(lang);
    }
}
