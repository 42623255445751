/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Component, ElementRef, Input,  Output, ViewChild, EventEmitter, Renderer2, Optional,  } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { InputResult } from '../../models/generic-input-result.model';

@Component({
    selector: 'app-generic-input',
    templateUrl: './generic-input.component.html',
    styleUrls: ['./generic-input.component.scss']
})
export class GenericInputComponent {

    @ViewChild('input')
    input!: ElementRef<HTMLInputElement>;

    disabled = false;

    @Input() type = 'text';

    @Input() isRequired = false;

    @Input() pattern = '';

    @Input() label = '';

    @Input() placeholder = '';

    @Input() errorMsg = '';

    @Input() iconOnTouch = true;

    @Input() autocomplete: 'on' | 'off' = 'off';

    @Input() infoDescription ='';
    
    // @Input() appearance: MatFormFieldAppearance = 'legacy';

    @Output() fieldResult: EventEmitter<InputResult> = new EventEmitter();

    constructor(
    
       @Optional()  public controlDir: NgControl,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {
        this.controlDir.valueAccessor = this;
    }

    writeValue(obj: any): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', obj);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange(event: any): void { }

    onChangeHandler(event: any): void {
        this.onChange(event.value)
    }

    onTouched(): void { }

    emitFieldResult(): void {
        this.fieldResult.emit(new InputResult({
            isValid: (this.controlDir?.valid || false),
            value: this.controlDir?.value
        }));
    }

}
