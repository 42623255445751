/**
* 2021 Infosys Limited, Bangalore, India. All Rights Reserved.
* Version: 1
*
* Except for any free or open source software components
* embedded in this Infosys proprietary software program,
* this Program is protected by copyright laws, international treaties
* and other pending or existing intellectual property rights in India,
* the United States and other countries. Except as expressly permitted,
* any unauthorized reproduction, storage, transmission in any form or
* by any means (including without limitation electronic, mechanical,
* printing, photocopying, recording or otherwise), or any distribution
* of this Program, or any portion of it, may result in severe civil and criminal
* penalties, and will be prosecuted to the maximum extent possible under the law.
*/
import { Component } from '@angular/core';
import { LoaderService } from '../../../shared/services/loader-service/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    showLoader = false;
    loaderInstance = 0;
    constructor(private loaderService: LoaderService) {
        this.loaderService.toggleLoader = this.toggleLoader.bind(this)
        this.loaderService.clearLoader = this.clearLoader.bind(this)
    }

    private toggleLoader(showLoader: boolean) {
        if (showLoader) {
            this.loaderInstance += 1;
            this.showLoader = true;
        } else {
            if (this.loaderInstance) {
                this.loaderInstance -= 1;
                if (!this.loaderInstance) {
                    this.showLoader = false;
                }
            }
        }
    }

    private clearLoader() {
        this.loaderInstance = 0;
        this.showLoader = false;
    }

}
