<mat-form-field
    class="width-100-percent generic-input">
    <mat-label *ngIf="label">{{label | translate}}</mat-label>
    <input matInput
        [type]="type"
        #input
        (input)="onChangeHandler($event.target)"
        (blur)="onTouched()"
        (keyup)="emitFieldResult()"
        
        [disabled]="disabled"
        [required]="isRequired"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
        [value]="controlDir.value"
        >
    <mat-hint class="mat-error" *ngIf="controlDir.errors?.errorMsg && controlDir.touched">
        {{controlDir.errors?.errorMsg | translate :  controlDir.errors?.params }}
    </mat-hint>

    <span matPrefix>
        <ng-content select="[prefix]"></ng-content>
    </span>

    <span matSuffix>
        <ng-content select="[suffix]"></ng-content>
    </span>

</mat-form-field>