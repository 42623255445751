import { Component } from '@angular/core';
import { LoaderService } from '../shared/services/loader-service/loader.service';
import { ButtonTypeEnum } from '../shared/enums/btn.enum';
import { FormControl } from '@angular/forms';
import { LoginService } from '../login/services/login.service';
import { IpAddressService } from '../application/services/ipAddress/ip-address.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-store',
  templateUrl: './search-store.component.html',
  styleUrls: ['./search-store.component.scss']
})
export class SearchStoreComponent {
  buttonType = ButtonTypeEnum;
  searchText: any;
  allStores: any;
  noStores: boolean = false;
  constructor(private loaderService: LoaderService,
              private searchStoreService: IpAddressService,
              private router: Router) { }

  ngOnInit() {
    this.loaderService.toggleLoader(false);
    this.fetchAllStores();
  }

  fetchAllStores() {
    let obj = {
      "searchString": this.searchText ? this.searchText : '',
      "page": 0,
      "limit": 50
    }

    this.searchStoreService.getAllStores(obj).subscribe(
      (res:any) => {
        this.allStores = res.response.storeNameResponse;
        this.noStores = res.response.totalCount == 0;
      },
      (error:any) => {
        this.allStores = [];
        this.noStores = true;
      },
    );
  }

  onStoreClick(store:any) {
    localStorage.setItem('storeName', store);
    this.router.navigate(['/store']);
    localStorage.removeItem('onCancel');
  }

  onCancel() {
    this.router.navigate(['/store']);
    localStorage.setItem('onCancel', 'true');
    localStorage.removeItem('storeID');
  }
}
